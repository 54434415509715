<template>
  <div>
    <b-modal
      v-model="showModal"
      hide-header
      hide-footer
      centered
      close-on-backdrop
    >
      <div class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <div
        class="preview-box b-contain img"
        v-bind:style="{
          'background-image': 'url(' + img + ')',
        }"
      ></div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.img {
  width: 100%;
  padding-bottom: 90%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.modal-body {
  margin: auto;
  text-align: center;
}
</style>
